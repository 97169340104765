import Q from 'q';
import _ from 'underscore';
import to_boolean from 'nllib/src/convert/to_boolean';
import wait_util from 'nllib/src/common/wait_util';

export default {
    components: {},
    data() {
        return {
            entity_list: [],

            loading: true,

            nav_btn_container: {
                style: {
                    top: `0`,
                },
            },
        };
    },
    methods: {
        __init_data() {
            const __this = this;
            const __init_entity_list = __this.__init_entity_list;
            const __init_nav_btn_container = __this.__init_nav_btn_container;

            __init_entity_list();
            __init_nav_btn_container();
        },

        __init_entity_list() {
            const __this = this;

            const entity_list = [];

            for (let i = 0; i < 10; i++) {
                const entity = {
                    id: i,
                };
                entity_list.push(entity);
            }

            __this.entity_list = entity_list;
        },

        __init_nav_btn_container() {
            const __this = this;
            const nav_btn_container = __this.nav_btn_container;

            return Q.when()
                .then(function () {
                    const $e = document.querySelector(`.nav-btn-container`);
                    const aOffsetHeight = $e.offsetHeight;
                    const aClientHeight = document.documentElement.clientHeight;
                    const aTopValue = (aClientHeight - aOffsetHeight) / 2;
                    const top = `${aTopValue}px`;

                    // console.log(`aTopValue=`, aTopValue);
                    // console.log(`top=`, top);

                    const nav_btn_container = __this.nav_btn_container;
                    nav_btn_container.style.top = top;
                })
                .catch(function (ex) {
                    if (!ex) return Q.reject(ex);
                    const enable_log_error = !ex.disable_log_error;
                    if (!enable_log_error) return Q.reject(ex);
                    console.error(ex);
                    console.trace();
                    if (ex.stack) console.error(ex.stack);
                    return Q.reject(ex);
                })
                ;
        },

        __btn_nav_click_event_handler(pEntity) {
            const __this = this;
            const id = pEntity.id;
            const selector_card = `#card-${id}`;
            const aOffsetTop = document.querySelector(selector_card).offsetTop;
            console.log(`aOffsetTop=`, aOffsetTop);
            document.documentElement.scrollTo({
                top: aOffsetTop,
                left: 0,
                behavior: 'smooth',
                // behavior: 'instant',
                // behavior: 'auto',
            });
        },
    },
    created() {
        // const __this = this;
    },
    mounted() {
        const __this = this;

        const window_scroll_event_handler = function (event) {

            const scroll_top = document.documentElement.scrollTop || document.body.scrollTop;

            //变量window_height是可视区的高度
            const window_height = document.documentElement.clientHeight || document.body.clientHeight;

            //变量scroll_height是滚动条的总高度
            const scroll_height = document.documentElement.scrollHeight || document.body.scrollHeight;

            // console.log(`scroll_top:`, scroll_top, `||window_height:`, window_height, `||scroll_height:`, scroll_height);

            if ((scroll_top + window_height) >= scroll_height) {
                const msg = `到底了！`;
                console.log(msg);
                __this.$message(msg);
            }
        };
        window.addEventListener('scroll', window_scroll_event_handler, true);
    },
    activated() {
        const __this = this;
        const __init_data = __this.__init_data;
        __init_data();
    },
    deactivated() {
        // const __this = this;
    },
}
